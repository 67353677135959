<template id="encyclopedia-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <MarkdownContent :source="content"></MarkdownContent>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, modalController } from '@ionic/vue';
import { defineComponent, computed, watch, ref } from 'vue';

import { useStore } from 'vuex';

import { useI18n } from "@/utils/i18n";

import { apiErrorToast } from '@/utils/error';

import MarkdownContent from '@/components/MarkdownContent.vue';

const EncyclopediaModal = defineComponent({
  name: 'EncyclopediaModal',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, MarkdownContent },
  props: {
    'id': String
  },
  setup(props) {
    const i18n = useI18n();
    const store = useStore();

    const title = computed(() => {
      if (entry.value != null && entry.value.descriptor != null) {
        return entry.value.descriptor;
      } else {
        return i18n.$t('encyclopedia.modal.loading_title');
      }
    });

    const content = computed(() => {
      if (entry.value != null && entry.value.text != null) {
        return entry.value.text;
      }
      return undefined;
    }) //TODO Add loading spinner

    const entry = ref(null);

    const closeModal = function(){
      modalController.dismiss();
    }

    //Watch for language change and fetch it
    watch(i18n.locale, (locale) => {
      if (locale != null) {
        store.dispatch('encyclopedia/fetchEncyclopediaEntry', { identifier: props.id, locale: locale })
        .then((newEntry) => entry.value = newEntry)
        .catch((error) => {
          entry.value = null;
          apiErrorToast(i18n, error, true).then(() => closeModal())
          throw error;
        });
      }
    }, { immediate: true});

    return { i18n, title, content, closeModal };
  }
});

export async function openEncyclopediaModal(component, id){
  if (component != null && (id != null)) {
    const modal = await modalController
      .create({
        component,
        componentProps: {
          id
        },
      })
    modal.present();
    return modal.onWillDismiss();
  }
}

export default EncyclopediaModal;
</script>

<style scoped>
  ion-title {
    font-size: clamp(.8em, 4vw, 20px);
    font-weight: 500;
    letter-spacing: 0.0125em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ion-content > * {
    margin: 20px;
  }
</style>