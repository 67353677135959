<template>
  <ion-page>
    <ion-header>
      <MainToolbar :title="i18n.$t('encyclopedia.title')" />
    </ion-header>
    <ion-content>
      <ion-searchbar :placeholder="i18n.$t('default_interaction.search')" @keyup="blurOnEnter" @ionChange="setSearchTerm($event.target.value)"></ion-searchbar>
      
      <template v-for="(type, typeIndex) in sortedEncyclopediaEntries" :key="typeIndex">
        <div class="wrapping-title">
          <ion-label>{{i18n.$t(`encyclopedia.types.${type.name}`)}}</ion-label>
        </div>

        <ion-card class="list-card">
          <ion-list>
            <!-- TODO Sort main_categories differently! -->
            <CollapsibleList v-for="(category, categoryIndex) in localizeAndSortArray(type.items, getLocalizedMainCategory)" :key="categoryIndex" 
              class="encyclopedia-category-header"
              :title="category.descriptor" >
              <template v-for="(entry, entryIndex) in localizeAndSortArray(category.items, getTranslationForEntry)" :key="entryIndex"> <!-- TODO Implement search for the encyclopedia too -->
                <ion-item class="entry-item" @click="openEncyclopediaEntry(entry.id)" detail button>
                  <ion-label class="entry-label">
                    <h1 class="wrapping-text">{{entry['descriptor']}}</h1>
                    <h3 class="wrapping-text">{{entry['text']}}&mldr;</h3>
                  </ion-label>
                </ion-item>
              </template>
            </CollapsibleList>
          </ion-list>
        </ion-card>
      </template>

      <div class="wrapping-title">
        <ion-label><span v-if="serviceTypeDescriptor != null">{{ serviceTypeDescriptor }} - </span>{{i18n.$t('services.title')}}</ion-label>
      </div>

      <ion-card class="list-card">
        <ServicesSelection :defaultServiceType="serviceType" :filterTerm="activeSearch" previewMode></ServicesSelection>
      </ion-card>
    </ion-content>
  </ion-page>
  
</template>

<script>
import { IonPage, IonHeader, IonContent, IonSearchbar, IonList, IonItem, IonLabel, IonCard } from '@ionic/vue';
import { defineComponent, defineAsyncComponent, watch, ref, computed } from 'vue';

import { useStore } from 'vuex';

import { blurOnEnter } from '@/utils/interaction';
import { serviceDefinitions } from '@/utils/services';

import MainToolbar from '@/components/MainToolbar.vue';
import CollapsibleList from '@/components/CollapsibleList.vue';
import { openEncyclopediaModal, default as encyclopediaModalComponent } from '@/components/EncyclopediaModal.vue';

const ServicesSelection = defineAsyncComponent(() => import('@/components/ServicesSelection.vue'));

import { useI18n } from "@/utils/i18n";

import _ from 'lodash';

export default defineComponent({
  name: 'Encyclopedia',
  components: { IonHeader, IonContent, IonPage, IonSearchbar, IonList, IonItem, IonLabel, IonCard, MainToolbar, CollapsibleList, ServicesSelection },
  setup() {
    const i18n = useI18n();
    const store = useStore();

    const TYPE_SORT_ORDER = ['disease', 'therapy'];

    const sortedEncyclopediaEntries = ref([]);

    const isOffline = computed(() => {
      return !(store.getters.isOnline);
    });

    const activeSearch = ref(null); //TODO Do search!

    const setSearchTerm = function(searchTerm) {
      (async () => activeSearch.value = searchTerm)();
    }

    const serviceType = computed(() => 'GOT'); //TODO Allow user to set the shown service type
    const serviceTypeDescriptor = computed(() => {
      if (serviceDefinitions[serviceType.value] != null) {
        return serviceDefinitions[serviceType.value].descriptor;
      }
      return undefined;
    })

    watch(store.getters['encyclopedia/getEncyclopediaIndexAsyncFunction'], (newIndexAsyncFunction) => {
      newIndexAsyncFunction.then((newIndex) => {
        sortedEncyclopediaEntries.value = _.sortBy(_.map(newIndex, (categorizedEntries, type) => {
          return {
            name: type,
            items: _.map(categorizedEntries, (entries, category) => {
              return {
                id: (category != 'null') ? category : null, //Filter out general category as null
                items: _.map(entries, (entry, entryId) => {
                  return {
                    id: entryId,
                    details: entry
                  }
                })
              };
            })
          };
        }), (typeName) => (_.indexOf(TYPE_SORT_ORDER, typeName))); //If not in sort order array, it will be sorted to the front
      })
    }, { immediate: true });

    const FALLBACK_LOCALE = 'en';

    const getTranslationForEntry = computed(() => {
      let locale = i18n.locale.value;
      return function(entry) {
        let translation;
        if (entry.details != null && entry.details.translations != null) {
          //First try to get the users locale
          if (locale in entry.details.translations) {
            translation = entry.details.translations[locale];
          }
          //If it doesn't exist, try the fallback locale
          else if (FALLBACK_LOCALE in entry.details.translations) {
            translation = entry.details.translations[FALLBACK_LOCALE];
          }
          //If none exists, give back any locale found
          else {
            translation = entry.details.translations[Object.keys(entry.details.translations)[0]];
          }
        }

        return {
          ...entry,
          ...translation
        }
      }
    });

    const getLocalizedMainCategory = function(mainCategory){
      let descriptor;
      if (mainCategory != null) {
        let categoryNames = store.getters['reports/getMainCategoryNamesById'](mainCategory.id);
        if (categoryNames) {
          descriptor = categoryNames[i18n.locale.value];
        } else {
          descriptor = i18n.$t('encyclopedia.categories.general');
        }
      }
      return {
        ...mainCategory,
        descriptor
      }
    }

    const descriptorSortFunction = computed(() => {
      let locale = i18n.locale.value;

      return function(firstObject, secondObject) {
        let firstDescriptor = (firstObject != null) ? firstObject.descriptor : null;
        let secondDescriptor = (secondObject != null) ? secondObject.descriptor : null;

        //Move undefined or null always to the front
        //Save both null conditions
        let firstNullValue = (firstDescriptor == null || firstDescriptor == 'null' || firstDescriptor == 'undefined');
        let secondNullValue = (secondDescriptor == null || secondDescriptor == 'null' || secondDescriptor == 'undefined');

        //If first is null and second is not, move first before second
        if (firstNullValue && !secondNullValue) return -1;
        //If second is null and first is not, move first after second
        if (!firstNullValue && secondNullValue) return 1;
        //If both are null keep them in that order
        if (firstNullValue && secondNullValue) return 0;
        //Otherwise compare the descriptor
        return firstDescriptor.localeCompare(secondDescriptor, locale);
      }
    });

    const localizeAndSortArray = computed(() => {
      let sortFunction = descriptorSortFunction.value;
      return function(array, localizationFunction = (objectToLocalize) => objectToLocalize) { //Localization function needs to return the localized object with "descriptor" being set for sorting
        return _.map(array, localizationFunction).sort(sortFunction);
      }
    });

    const openEncyclopediaEntry = function(identifier) {
      if (identifier != null) openEncyclopediaModal(encyclopediaModalComponent, identifier);
    }

    return { i18n, serviceType, serviceTypeDescriptor, isOffline, activeSearch, setSearchTerm, blurOnEnter, sortedEncyclopediaEntries, getTranslationForEntry, getLocalizedMainCategory, localizeAndSortArray, openEncyclopediaEntry };
  }
});
</script>

<style scoped>
.wrapping-title {
  padding: 20px 15px 0px;
  font-weight: 500;
  font-size: 1.35em;
  color: var(--ion-color-dark-tint, gray);
  white-space: normal!important;
  /* Prevent text selection in label */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wrapping-text {
  white-space: normal!important;
  /* Prevent text selection in label */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.list-card {
  margin-left: 20px;
  margin-right: 20px;
  --background: var(--ion-card-background, #fff);
}

.list-card ion-list {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
}

.entry-label h1 {
  font-weight: 500;
  font-size: 1.35em;
}

ion-searchbar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;
  background: var(--ion-background-color, #fff);
  --background: var(--ion-card-background, #fff);
}

.entry-item {
  --background: var(--ion-card-background, #fff);
}

/* Remove line under last item */
.entry-item:last-child {
  --inner-border-width: 0px 0px 0px 0px;
}

.encyclopedia-category-header {
  --color: var(--ion-color-primary-text);
  color: var(--color);
}
</style>
